import { Routes } from '@angular/router';

export const searchMasjidsRoutes: Routes = [
    {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
    },
    {
        path: '',
        children: [
            {
                // Dedicated route for search results
                path: 'results',
                loadComponent: () => import('./pages/search-results-page.component')
                    .then(m => m.SearchResultsPageComponent)
            },
            {
                // Results with query parameters
                path: 'results/:query',
                loadComponent: () => import('./pages/search-results-page.component')
                    .then(m => m.SearchResultsPageComponent)
            },
            {
                // For advanced search with filters
                path: 'advanced',
                loadComponent: () => import('./components/advanced-search/advanced-search.component')
                    .then(m => m.AdvancedSearchComponent)
            }
        ]
    }
]; 